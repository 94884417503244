<template>
  <v-container class="form-container">
    <div v-if="emailSent" class="mt-5 mb-2 text-medium">
      <div>認証されました。ログイン用のURLをメールでお送りいたしましたのでご確認ください。</div>
      <div class="mt-4">メールが確認できましたら画面を閉じてください。</div>
    </div>
    <template v-else>
      <!-- START Login-form -->
      <v-form
        class="d-flex flex-column align-center mt-5"
        ref="form"
        lazy-validation
        v-on:submit.prevent
      >
        <v-text-field
          v-model="email"
          :rules="[$rules.required, $rules.email]"
          :label="$t('common.emailAddress')"
          name="email"
          type="email"
          class="w-100"
          solo
          outlined
          flat
          required
        />
        <PasswordInput
          v-model="password"
          :label="$t('common.password')"
          class="w-100"
        />
        <div class="text-center">
          <v-checkbox
            v-model="rememberLoginEmail"
            :label="$t('loginPage.saveYourLoginID')"
            hide-details
            :ripple="false"
            class="mt-0"
            :class="{ 'check-shadow': !rememberLoginEmail }"
          ></v-checkbox>
        </div>
        <router-link to="/forgot-password">
          <v-btn
            elevation="0"
            text
            color="var(--color__primary)"
            class="text-decoration-underline mt-2 mb-5"
          >
            {{ $t("loginPage.forgotYourPassword") }}
          </v-btn>
        </router-link>
        <p class="px-1">{{ $t("loginPage.forgotYourId") }}</p>
        <a
          href="/static/docs/Vacation会員ページ規約.pdf"
          class="text-decoration-underline mt-2 mb-5"
          target="_blank"
        >
          {{ $t('loginPage.membershipAgree') }}
        </a>
        <v-btn
          elevation="1"
          @click="submitForm"
          color="white"
          block
          rounded
          x-large
        >
          <span class="w-100 --prm">{{ $t("common.login") }}</span>
          <v-icon color="var(--color__primary)">mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </v-form>
    </template>

    <v-container>
      <div class="d-flex flex-column align-center">
        <v-btn
          elevation="0"
          href="https://www.tokyu-vacations.com" target="_blank"
          class="btn-grad--prm btn-icon mt-4 mb-6"
          block
          rounded
          x-large
        >
          <span class="w-100">{{ $t("buttons.toTokyuWebsite") }}</span>
          <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
        </v-btn>

        <logo-both-tokyu />

      </div>
    </v-container>
  </v-container>
</template>

<script>
import LogoBothTokyu from '@/components/icons/LogoBothTokyu'
import PasswordInput from '../../components/Inputs/PasswordInput.vue'

export default {
  name: 'Login',
  components: { LogoBothTokyu, PasswordInput },
  data () {
    return {
      // ----- Login-form -----
      email: this.$store.getters.savedLoginEmail,
      password: '',
      rememberLoginEmail: this.$store.getters.rememberLoginEmail,
      emailSent: false
    }
  },
  methods: {
    async submitForm () {
      if (this.$refs.form.validate()) {
        await this.$doLoading(async () => {
          const loginUrl = await this.$showGqlError(async () => {
            const result = await this.$store.dispatch('requestMFAClient', {
              apollo: this.$apollo,
              email: this.email,
              password: this.password
            })

            return result.data.requestMFAClient.loginUrl
          })
          if (loginUrl) {
            // skip 2FA, redirect to loginUrl
            window.location.replace(loginUrl)
            return
          }

          this.$toast.info(this.$t('toast.checkEmail').toString(), {
            duration: 0
          })
          this.emailSent = true
          this.password = ''

          // Save login before redirect
          await this.$store.commit('setSavedLoginEmail', this.$store.getters.rememberLoginEmail ? this.email : '')
        })
      }
    }
  },
  watch: {
    rememberLoginEmail (val) {
      this.$store.commit('setRememberLoginEmail', val)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../../assets/styles/modules/variables-scss'as v;

div.bg-dark-silver {
  border-radius: 20px;
  box-shadow: -3px -3px 6px var(--color__gray);

  @media (min-width: v.$breakpoint__md) {
    border-radius: 0;
    box-shadow: none;
  }
}

.custom-wrapper {
  min-height: calc(100vh - 83px - 143px - 56px);
  height: auto;
}
</style>
